<template>
  <div class="reservations-list">
    <PageTitleExtra
      title="預約列表"
      btn="新增"
      btn2="關閉時段"
      btn3="匯出"
      btnFeature="admin.appointmentOrder.create"
      cyBtn1="new-reservation-btn"
      @btnClick="$router.push({ name: 'ReservationCreate' })"
      @btn2Click="togglePeriodDialog = true"
      @btn3Click="showExportOptions = true"
    />
    <!-- <header class="view-title">預約列表</header> -->
    <div class="filters-wrapper">
      <div class="filter-row">
        <p class="label">篩選</p>
        <div class="flex w-full gap-[8px]">
          <div class="flex gap-[8px] w-full">
            <el-date-picker
              v-model="search.date"
              editable
              class="test2"
              :class="{'date-picker-width': !showCustomFlow}"
              type="daterange"
              start-placeholder="預約開始日期"
              end-placeholder="預約结束日期"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="選擇預約日期"
              @change="refresh(true)"
            />
            <CustomFlowSelect
              :showCustomFlow="showCustomFlow"
              :search="search"
              :displayCustomFlowConfig="displayCustomFlowConfig"
              :customFlowList="customFlowList"
              @refresh="refresh(true)"
            />
          </div>
        </div>
      </div>
      <div class="filter-row">
        <p class="label">搜尋</p>
        <div class="flex w-full flex-col gap-[8px]">
          <div class="flex gap-[8px]">
            <BaseElInput
              v-model="search.userName"
              testName="search_name"
              class="test2"
              clearable
              placeholder="搜尋預約人姓名"
              @keypress.enter.native="refresh(true)"
              @clear="refresh(true)"
            >
              <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh(true)" />
            </BaseElInput>

            <ServiceUnitSearch
              class="test2"
              testName="search_serviceUnit"
              :model.sync="search.unit"
              @change="refresh"
            />
          </div>
          <div class="flex gap-[8px]">
            <ResourceSearch
              v-if="checkAction('admin.resourceItem.page')"
              class="test2"
              testName="search_resource"
              :model.sync="search.resource"
              @change="refresh"
            />
            <BaseElInput
              v-model="search.code"
              testName="search_orderCode"
              class="test2"
              clearable
              placeholder="搜尋訂單編號"
              @keypress.enter.native="refresh(true)"
              @clear="refresh(true)"
            >
              <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh" />
            </BaseElInput>
          </div>
        </div>
      </div>
    </div>

    <section class="flex justify-between items-end">
      <div class="flex-1">
        <p>當月預約訂單總數：{{ monthRecordCount }} / 每月預約上限：{{ contractReservationsCount }}</p>
        <ProgressBar :percentage="reservationsPercentage" />
      </div>
      <TableFilter
        style="margin-left: 15px"
        :tableName="tableName"
        :options="reservationListFilterOptions"
        @update="updateTableFilter"
      />
    </section>

    <section>
      <BaseTable v-if="!tableRefresh" v-loading="loading" :data="reservationsList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="code" label="訂單編號" width="120" fixed align="center" />
        <BaseElTableColumn v-if="showColumn('orderType', tableFilter)" label="訂單類型" fixed align="center">
          <template slot-scope="scope">
            {{ getData(scope.row, 'AppointmentOrder.peopleCount', 1) > 1 ? '多人' : '單人' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="userName" label="姓名" fixed align="center">
          <template slot-scope="scope">
            {{ scope.row.userName || (scope.row.numberCode >= 2 ? '未填寫' : '') }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('phone', tableFilter)" prop="userPhone" label="電話號碼" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.userPhone || (scope.row.numberCode >= 2 ? '未填寫' : '') }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('date', tableFilter)" prop="start" label="預約日期" width="115" align="center">
          <template slot-scope="scope">
            {{ dateTime(scope.row.start).date }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('time', tableFilter)" prop="start" label="預約時間" width="115" align="center">
          <template slot-scope="scope">
            {{ dateTime(scope.row.start).time }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('checkInTime', tableFilter) && checkAction('admin.checkInPageSetting.page')" prop="checkInTime" label="報到時間" width="115" align="center">
          <template slot-scope="scope">
            {{ get(scope.row,'AppointmentOrder.AppointmentOrderCheckIn') ? dateTime(get(scope.row,'AppointmentOrder.AppointmentOrderCheckIn.checkInAt')).dateTime : '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('checkInBranch', tableFilter) && checkAction('admin.checkInPageSetting.page')" prop="checkInBranch" label="報到門市" width="115" align="center">
          <template slot-scope="scope">
            {{ get(scope.row,'AppointmentOrder.AppointmentOrderCheckIn') ? get(scope.row,'AppointmentOrder.AppointmentOrderCheckIn.Branch.name') : '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('service', tableFilter)" prop="AppointmentService.name" label="服務項目" width="150" align="center" />
        <BaseElTableColumn v-if="showColumn('serviceUnit', tableFilter)" prop="AppointmentUnit.name" label="服務人員" width="150" align="center">
          <template slot-scope="scope">
            {{ showNotSpecify(scope.row) || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('resourceItem', tableFilter) && checkAction('admin.resourceItem.page')" prop="ResourceItem.name" label="服務設備" width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.ResourceItem?.name || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('userAddress', tableFilter) && useMemberAddress" prop="AppointmentOrder.userAddress" label="收件地點" align="center" width="180">
          <template slot-scope="scope">
            {{ scope.row.AppointmentOrder.userAddress || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('origin', tableFilter)" prop="origin" label="預約來源" width="120" align="center">
          <template slot-scope="scope">
            {{ reservationOrigins(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('createdAt', tableFilter)" prop="createdAt" label="建立時間" width="120" align="center">
          <template slot-scope="scope">
            {{ createdAtFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>

        <!-- 訂金欄位 -->
        <BaseElTableColumn v-if="showColumn('orderPrice', tableFilter) && useDeposit" label="訂單總額" prop="status" width="105" align="center">
          <template slot-scope="scope">
            {{ orderTotalPrice(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('depositPrice', tableFilter) && useDeposit" label="應付訂金" prop="status" width="105" align="center">
          <template slot-scope="scope">
            {{ depositPrice(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('paymentType', tableFilter) && useDeposit" label="付款方式" prop="status" width="150" align="center">
          <template slot-scope="scope">
            {{ depositPaymentType(scope.row) }}
          </template>
        </BaseElTableColumn>

        <CustomFlowTableColumns
          :customFlowConfig="displayCustomFlowConfig"
          :tableFilterConfig="tableFilter"
          scope="appointment"
        />

        <BaseElTableColumn v-if="showColumn('checkoutTotalPrice', tableFilter)" label="實付金額">
          <template slot-scope="scope">
            {{ getData(scope.row, 'AppointmentOrder.AppointmentCheckoutOrder.totalPrice', '-') }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="showColumn('checkoutPaymentType', tableFilter)" label="結帳付款方式" width="120px" align="center">
          <template slot-scope="scope">
            {{ displayCheckoutPaymentType(scope.row) }}
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          v-if="showColumn('checkoutPaymentStatus', tableFilter)"
          label="結帳付款狀態"
          prop="status"
          width="105"
          align="center"
        >
          <template slot-scope="reservation">
            <Tag
              v-if="getData(reservation.row, 'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment')"
              :type="paymentStatusTagType(reservation.row, 'checkout')"
              disable-transitions
            >
              {{ paymentStatus(reservation.row, 'checkout') }}
            </Tag>
            <span v-else>-</span>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          v-if="showColumn('depositPaymentStatus', tableFilter) && useDeposit"
          label="付款狀態"
          prop="status"
          width="105"
          align="center"
        >
          <template slot-scope="reservation">
            <Tag
              v-if="getData(reservation.row, 'AppointmentOrder.AppointmentOrderDeposit')"
              :type="paymentStatusTagType(reservation.row, 'deposit')"
              disable-transitions
            >
              {{ paymentStatus(reservation.row, 'deposit') }}
            </Tag>
            <span v-else>-</span>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn fixed="right" label="訂單狀態" prop="status" width="105" align="center">
          <template slot-scope="reservation">
            <Tag disable-transitions :type="orderStatus(getData(reservation.row, 'AppointmentOrder.status'), 'tag')">
              <!-- {{ statusTrans(getData(reservation.row, 'AppointmentOrder.status', '')) }} -->
              {{ orderStatus(getData(reservation.row, 'AppointmentOrder.status'), 'name') }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn fixed="right" label="操作" width="60" align="center">
          <template slot-scope="reservation">
            <TableSettingIconButton :testName="`detail-reservation-${reservation.$index}`" @click="onDetail(reservation.row)" />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="reservationsCount"
        @pageChange="refresh"
      />
    </section>

    <!-- 預約資訊側邊彈窗 -->
    <ReservationOrderDetail
      v-if="showInfo"
      :orderId="selectRow.id"
      :selecReservation="selectRow"
      :customFlowConfig="displayCustomFlowConfig"
      @close="showInfo=false"
      @cancel="onCancel"
      @refresh="refresh"
    />

    <!-- 關閉時段 Dialog -->
    <TogglePeriodDialog v-if="togglePeriodDialog" title="編輯關閉時段" width="440px" @close="togglePeriodDialog = false" />

    <DeleteDialog
      v-if="deleteDialog"
      title="警示"
      :content="deleteContent"
      width="40%"
      btnString="取消預約"
      cancelBtnString="返回"
      @close="deleteDialog = false"
      @delete="cancelReservation()"
    />

    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      @close="showExportOptions = false"
      @export="prepareExport"
    />

    <ExportDialog
      v-if="exportState.modal"
      title="匯出預約紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import CustomFlowSelect from '@/components/Select/CustomFlowSelect.vue'
import PageTitleExtra from '@/components/Title/PageTitleExtra.vue'
import TableFilter from '@/components/Button/TableFilter.vue'
import { reservationListFilterOptions as reservationListFilterOptionsConfig } from '@/config/table'
import { mapGetters } from 'vuex'
import ProgressBar from '@/components/Progress/ProgressBar'
import ReservationOrderDetail from '@/components/Reservation/ReservationOrderDetail/ReservationOrderDetail.vue'
import { getTableFilterConfig, showColumn } from '@/utils/tableFilter'
import {
  GetReservation,
  GetReservationCount,
  UpdateReservationOrderStatus,
  GetReservationOrderCount,
} from '@/api/reservation'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import TogglePeriodDialog from '@/components/Dialog/TogglePeriodDialog.vue'
import ServiceUnitSearch from '@/components/Search/ServiceUnitSearch.vue'
import ResourceSearch from '@/components/Search/ResourceSearch.vue'
import CustomFlowTableColumns from '@/components/CustomFlowTableColumns.vue'
// import ServiceSearch from '@/components/Search/ServiceSearch.vue'
import { getData } from '@/utils/object'
import EmptyBlock from '@/components/EmptyBlock.vue'
import dayjs from '@/lib/dayjs'
// Utils
import { pageStartIndex } from '@/utils/table'
import {
  dateTimeFormat,
  statusTranslate,
  getAllDataFromApi,
} from '@/utils/helper'
import { getMonth, formatDate } from '@/utils/date'
import { ExportExcel } from '@/utils/excel'

import { reservationOrderOrigins } from '@/utils/reservation'
import { get, map, join, filter, compact, uniqBy, isEmpty, find } from 'lodash'
import { depositPaymentTypes, depositStatusConfig } from '@/config/deposit'
import { orderStatusConfig } from '@/config/reservation'
import { paymentOptionsConfig } from '@/config/payment'
import { invoiceStatusConfig, invoiceTypeConfig } from '@/config/pubApt'
import { usePermissions } from '@/use/permissions'
import { useExport } from '@/use/export'
import { useShop } from '@/use/shop'
import { reactive, ref, computed, onMounted } from 'vue'
import { useCustomFlow } from '@/use/useCustomFlow'
import { usePayment } from '@/use/usePayment'
import { useExternalWallet } from '@/use/useExternalWallet'

export default {
  name: 'ReservationsList',
  components: {
    TableFilter,
    ProgressBar,
    DeleteDialog,
    TogglePeriodDialog,
    ServiceUnitSearch,
    ResourceSearch,
    // ServiceSearch,
    ReservationOrderDetail,
    EmptyBlock,
    PageTitleExtra,
    ExportDialog,
    ExportOptionsDialog,
    CustomFlowTableColumns,
    CustomFlowSelect,
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const { exportState, resetExport, getExportDataOld, totalDataCount, totalPercentege } = useExport()
    const { getDisplayPaymentType } = usePayment()
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const {
      customFlowConfig,
      displayCustomFlowConfig, getCustomFlowConfig, useCustomFlowFeature,
      getExportCustomFlowFieldsData,
    } = useCustomFlow('appointment')
    const { shopId } = useShop()
    const showExportOptions = ref(false)
    const selectRow = ref({
      AppointmentService: {},
      AppointmentUnit: {},
      AppointmentServiceAttaches: [],
    })
    const showInfo = ref(false)
    const tableFilter = ref([])

    const search = reactive({
      userName: '',
      service: '',
      resource: '',
      unit: '',
      date: '',
      code: '',
      customFlow: '',
      customFlowId: null,
    })
    const showCustomFlow = computed(() => get(useCustomFlowFeature, 'value.config') && !isEmpty(displayCustomFlowConfig.value)) // 有開權限以及有自定義流程
    const customFlowList = computed(() =>
      get(find(displayCustomFlowConfig.value, { id: search.customFlow }), 'nodes'),
    )
    const paymentStatus = (row, type) => {
      if (type === 'deposit') {
        const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
        if (!depositData) return '-'
        const status = depositData.status
        return get(depositStatusConfig[status], 'name')
      } else if (type === 'checkout') {
        const depositData = get(row, 'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment')
        if (!depositData) return '-'
        const status = depositData.status
        return get(depositStatusConfig[status], 'name')
      }
    }
    const showNotSpecify = (row) => {
      const notSpecify = row.fromNotSpecify
      if (row.AppointmentUnit) return notSpecify ? `${row.AppointmentUnit?.name} (不指定)` : row.AppointmentUnit?.name
      else return '-'
    }
    const reservationOrigins = (row) => {
      const notSpecify = row.fromNotSpecify
      const origin = getData(row, 'AppointmentOrder.origin', '')
      const originText = getData(row, 'AppointmentOrder.originText', '')
      return reservationOrderOrigins({ notSpecify, origin, originText })
    }
    const depositPaymentType = (row) => {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      const type = depositData.paidType
      if (type === 'offline') {
        const comment = depositData.paidTypeComment
        return get(depositPaymentTypes[comment], 'name')
      }
      if (type === 'externalWallet') {
        return externalWalletName.value
      }
      return get(depositPaymentTypes[type], 'name')
    }
    const displayCheckoutPaymentType = (row) => {
      const checkoutOrder = get(row, 'AppointmentOrder.AppointmentCheckoutOrder')
      const checkoutPaymentType = get(checkoutOrder, 'AppointmentCheckoutPayment.paidType')
      const checkoutPaymentTypeComment = get(checkoutOrder, 'AppointmentCheckoutPayment.paidTypeComment')
      const displayCheckoutPaidType = getDisplayPaymentType({
        paidType: checkoutPaymentType,
        paidTypeComment: checkoutPaymentTypeComment,
      })
      if (checkoutPaymentType === 'externalWallet') return externalWalletName.value

      return displayCheckoutPaidType
    }

    const formatExportData = (exportData) => {
      const data = []
      let haveDeposit = false

      exportData.forEach((item) => {
        const order = get(item, 'AppointmentOrder', {})
        const deposit = get(item, 'AppointmentOrder.AppointmentOrderDeposit')
        const attachServices = get(item, 'AppointmentServiceAttaches', [])

        if (deposit) haveDeposit = true
        const classTicketSerials = get(item, 'AppointmentReservationTicket.BookedRecordUse.ClassTicketRecordSerials') || []

        const customFlowFields = getExportCustomFlowFieldsData({
          displayCustomFlowConfig: displayCustomFlowConfig.value,
          itemData: item,
        })

        const checkoutOrder = get(item, 'AppointmentOrder.AppointmentCheckoutOrder')
        const checkoutPaymentType = get(checkoutOrder, 'AppointmentCheckoutPayment.paidType')
        const checkoutPaymentTypeComment = get(checkoutOrder, 'AppointmentCheckoutPayment.paidTypeComment')
        const displayCheckoutPaidType = getDisplayPaymentType({
          paidType: checkoutPaymentType,
          paidTypeComment: checkoutPaymentTypeComment,
        })

        const checkoutInvoiceType = get(checkoutOrder, 'AppointmentCheckoutInvoice.InvoiceContent.buyer.type')
        const checkoutInvoiceStatus = get(checkoutOrder, 'AppointmentCheckoutInvoice.status')
        const row = {
          訂單編號: get(order, 'code', '-'),
          預約單號: item.numberCode,
          訂單狀態: statusTranslate(item.status) || '-',
          付款狀態: paymentStatus(item, 'deposit'),
          訂單類型: get(order, 'peopleCount', 1) > 1 ? '多人' : '單人',
          會員編號: item.MemberId || '-',
          姓名: item.userName || '未填寫',
          電話號碼: item.userPhone || '未填寫',
          預約日期: dateTimeFormat(item.start).date,
          預約時間: dateTimeFormat(item.start).time,
          服務人員: showNotSpecify(item) || '-',
          服務設備: get(item, 'ResourceItem.name', '-'),
          預約收件地點: get(item, 'AppointmentOrder.userAddress') || '-',
          服務項目: get(item, 'AppointmentService.name', '-'),
          附加服務: map(attachServices, 'name').join('、') || '-',
          會員備註: item.userComment || '-',
          店家備註: get(order, 'adminComment') || '-',
          預約來源: reservationOrigins(item),
          建立時間: formatDate(item.createdAt),
          訂單總額: item.totalPrice || '-',
          應付訂金: get(deposit, 'depositPrice', '-'),
          付款方式: depositPaymentType(item),
          交易序號: get(deposit, 'code', '-'),
          堂票名稱: get(item, 'AppointmentReservationTicket.ClassTicketRecord.name') || '-',
          堂票總價: get(item, 'AppointmentReservationTicket.ClassTicketRecord.price') || '-',
          堂票序號: classTicketSerials.length ? join(map(classTicketSerials, 'code'), '、') : '-',
          ...customFlowFields,
          結帳付款狀態: paymentStatus(item, 'checkout'),
          結帳總額: get(checkoutOrder, 'totalItemsPrice', '-'),
          實付金額: get(checkoutOrder, 'totalPrice', '-'),
          結帳付款方式: displayCheckoutPaidType,
          結帳交易序號: get(checkoutOrder, 'AppointmentCheckoutPayment.code', '-'),
          發票狀態: get(invoiceStatusConfig, `${checkoutInvoiceStatus}.label`, '-'),
          發票種類: get(invoiceTypeConfig, `${checkoutInvoiceType}.label`, '-'),
          公司抬頭: get(item, '.AppointmentCheckoutInvoice.InvoiceContent.buyer.name', '-'),
          統一編號: get(item, 'AppointmentCheckoutInvoice.InvoiceContent.buyer.identifier', '-'),
          發票號碼: get(item, 'AppointmentCheckoutInvoice.InvoiceContent.invoiceNo', '-'),
        }

        data.push(row)
      })
      if (!haveDeposit) {
        data.forEach(row => {
          delete row['付款狀態']
          delete row['訂單總額']
          delete row['應付訂金']
          delete row['付款方式']
          delete row['交易序號']
        })
      }
      return data
    }

    const prepareExport = async ({ all, range }) => {
      showExportOptions.value = false
      exportState.modal = true
      exportState.exportting = true
      let dateStart
      let dateEnd
      if (search.date !== '' && search.date) {
        // const {
        //   start,
        //   end,
        // } = getDay(dayjs(search.date).format('YYYY/MM/DD'))
        dateStart = search.date[0]
        dateEnd = search.date[1]
      }
      const payload = {
        shopId: shopId.value,
        sort: (dateStart) ? 'start' : undefined,
        sortBy: (dateStart) ? 'ASC' : 'DESC',
        dateStart,
        dateEnd,
        userName: !search.userName ? undefined : search.userName.trim(),
        AppointmentUnitId: !search.unit ? undefined : search.unit.id,
        AppointmentServiceId: !search.service ? undefined : search.service.id,
        ResourceItemId: (search.resource === '') ? undefined : search.resource.id,
        code: !search.code ? undefined : search.code.trim(),
        customFlowNodeId: search.customFlowId || undefined,
        includeAppointmentOrderCheckIn: checkAction('admin.checkInPageSetting.page'),
        includeAppointmentReservationTicket: checkAction('admin.classTicket.page'),
        includeCustomFlowRecord: useCustomFlowFeature.value.record,
        includeAppointmentCheckoutOrder: showColumn('checkoutPaymentStatus', tableFilter.value),
        includeAppointmentOrderDeposit: showColumn('depositPaymentStatus', tableFilter.value) || showColumn('depositPrice', tableFilter.value),
      }

      try {
        const resCount = await GetReservationCount(payload)
        exportState.dataCount = [resCount]
        if (!totalDataCount.value) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          window.$message.warning('尚無資料可匯出')
          return
        }

        const resData = await getExportDataOld({ stage: 0, fetchAPI: GetReservation, payload })
        const formatData = formatExportData(resData)
        exportState.success = true
        exportState.content = '匯出完成'
        ExportExcel(formatData, '預約紀錄', '預約紀錄')
      } catch (error) {
        window.$message.error(error.message || error)
        console.log(error.message || error)
        exportState.exportting = false
        exportState.error = true
      }
    }

    const onDetail = (row) => {
      selectRow.value = row
      showInfo.value = true
    }
    onMounted(async() => {
      await getExternalWalletConfig()
    })
    return {
      showInfo,
      selectRow,
      exportState,
      prepareExport,
      tableFilter,
      showNotSpecify,
      paymentStatus,
      search,
      reservationOrigins,
      depositPaymentType,
      resetExport,
      totalPercentege,
      showExportOptions,
      checkAction,
      customFlowConfig,
      getCustomFlowConfig,
      displayCustomFlowConfig,
      useCustomFlowFeature,
      showCustomFlow,
      customFlowList,
      onDetail,
      paymentOptionsConfig,
      displayCheckoutPaymentType,
    }
  },
  data: () => ({
    // Export

    exportting: false,
    exportError: false,
    exportData: null,
    exportPercentage: 0,
    showExportDialog: false,

    tableRefresh: false,
    tableName: 'reservationList',
    // tableFilter: [],
    deleteContent: '',
    loading: false,
    deleteDialog: false,
    dialogType: '',
    togglePeriodDialog: false,
    options: {
      date: new Date(),
      name: '',
      service: '',
      serviceUnit: '',
    },
    reservationsList: [],
    reservationsCount: 0,
    monthRecordCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
    colorList: [{
      color: '#1989fa',
      percentage: 80,
    },
    {
      color: '#f56c6c',
      percentage: 100,
    },
    ],
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
      'userFeatures',
      'userPlanFeature',
    ]),
    reservationListFilterOptions () {
      const blockList = []
      if (!this.useDeposit) blockList.push(...['orderPrice', 'depositPrice', 'paymentType'])
      if (!this.useMemberAddress) blockList.push('userAddress')
      if (!this.checkAction('admin.resourceItem.page')) blockList.push('resourceItem')
      if (!this.checkAction('admin.checkInPageSetting.page')) blockList.push(...['checkInTime', 'checkInBranch'])

      const customFlows = compact(map(this.customFlowConfig, i => {
        if (i.enable) return { label: i.name, value: i.name }
        else return null
      }))

      reservationListFilterOptionsConfig.push(...customFlows)

      return uniqBy(filter(reservationListFilterOptionsConfig, i => !blockList.includes(i.value)), option => option.value)
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    contractReservationsCount () {
      return this.userPlanLimit.appointmentReservationLimitPerMonth
    },
    reservationsPercentage () {
      if (this.userPlanLimit.appointmentReservationLimitPerMonth === 0) {
        return 0
      }
      const count = Math.round(this.monthRecordCount * 100 / this.contractReservationsCount)
      return count > 100 ? 100 : count
    },
    useDeposit () {
      return this.checkAction('adminView.appointmentDeposit.enable')
    },
    useMemberAddress () {
      return this.checkAction('adminView.member.findUserInfoMoreAddress')
    },
  },
  watch: {
    reservationListFilterOptions (data) {
      this.tableFilter = getTableFilterConfig(this.tableName, this.reservationListFilterOptions)
    },
  },

  async mounted () {
    this.tableFilter = getTableFilterConfig(this.tableName, this.reservationListFilterOptions)
    await this.refresh()
  },
  methods: {
    showColumn,
    getData,
    get,
    onCancel (data) {
      const orders = get(data, 'AppointmentOrder.AppointmentReservations')
      const orderCode = get(data, 'AppointmentOrder.code')
      if (!orders) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }
      if (!orders.length || orders.length === 1) {
        this.deleteDialog = true
        this.deleteContent = '確定要取消此筆預約？'
        return
      }

      const ordersCount = orders.length

      this.deleteContent = `訂單編號 ${orderCode} 有 ${ordersCount} 筆預約紀錄，將同時取消 ${ordersCount} 筆預約紀錄。`
      this.deleteDialog = true
    },
    dateTime (dateTime) {
      return dateTimeFormat(dateTime)
    },
    statusTrans (status) {
      return statusTranslate(status)
    },

    orderStatus (status, attr) {
      return get(orderStatusConfig[status], attr)
    },
    tagType (val) {
      let type = 'info'
      if (val === 'complete') type = 'action'
      if (val === 'wait') type = 'info'
      if (val === 'cancel') type = 'danger'
      return type
    },
    async refresh (force = false) {
      this.loading = true
      if (force === true) this.tableOptions.page = 1
      await Promise.all([
        this.getReservation(),
        this.getReservationCount(),
        this.getMonthReservation(),
        this.getCustomFlowConfig(),
      ])
      this.loading = false
    },
    //= > 取得預約
    async getReservation (all) {
      try {
        let dateStart
        let dateEnd
        if (this.search.date !== '' && this.search.date) {
          // const {
          //   start,
          //   end,
          // } = getDay(dayjs(this.search.date).format('YYYY/MM/DD'))
          dateStart = this.search.date[0]
          dateEnd = this.search.date[1]
        }
        this.search.code = this.search.code.trim()
        const res = await GetReservation({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          sort: (dateStart) ? 'start' : undefined,
          sortBy: (dateStart) ? 'ASC' : 'DESC',
          dateStart,
          dateEnd,
          userName: (this.search.userName === '') ? undefined : this.search.userName.trim(),
          AppointmentUnitId: (this.search.unit === '') ? undefined : this.search.unit.id,
          AppointmentServiceId: (this.search.service === '') ? undefined : this.search.service.id,
          ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
          code: (this.search.code === '') ? undefined : this.search.code.trim(),
          includeAppointmentOrderCheckIn: this.checkAction('admin.checkInPageSetting.page'),
          includeAppointmentReservationTicket: this.checkAction('admin.classTicket.page'),
          includeCustomFlowRecord: this.useCustomFlowFeature.record,
          includeAppointmentOrderDeposit: showColumn('depositPaymentStatus', this.tableFilter) || showColumn('depositPrice', this.tableFilter),
          includeAppointmentCheckoutOrder: showColumn('checkoutPaymentStatus', this.tableFilter),
          customFlowNodeId: this.search.customFlowId || undefined,
        })
        this.reservationsList = res
        this.exportData = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    async getAllReservation () {
      let dateStart
      let dateEnd
      if (this.search.date !== '' && this.search.date) {
        // const {
        //   start,
        //   end,
        // } = getDay(dayjs(this.search.date).format('YYYY/MM/DD'))
        dateStart = this.search.date[0]
        dateEnd = this.search.date[1]
      }
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        sort: (dateStart) ? 'start' : undefined,
        sortBy: (dateStart) ? 'ASC' : 'DESC',
        dateStart,
        dateEnd,
        userName: (this.search.userName === '') ? undefined : this.search.userName.trim(),
        AppointmentUnitId: (this.search.unit === '') ? undefined : this.search.unit.id,
        AppointmentServiceId: (this.search.service === '') ? undefined : this.search.service.id,
        ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
        code: (this.search.code === '') ? undefined : this.search.code.trim(),
      }
      this.exportData = await getAllDataFromApi(
        this.reservationsCount,
        GetReservation,
        payload,
      )
    },
    //= > 取得本月預約數
    async getMonthReservation () {
      try {
        const now = dayjs(new Date())
        const year = now.format('YYYY')
        const month = now.format('MM')
        const {
          start,
          end,
        } = getMonth(year, month)

        const [res, err] = await GetReservationOrderCount({
          shopId: this.shop,
          dateStart: start,
          dateEnd: end,
        })
        if (err) return this.$message.error(err)
        this.monthRecordCount = res.count || 0
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 取得預約總數
    async getReservationCount () {
      try {
        let dateStart
        let dateEnd
        if (this.search.date !== '' && this.search.date) {
          // const {
          //   start,
          //   end,
          // } = getDay(dayjs(this.search.date).format('YYYY/MM/DD'))
          dateStart = this.search.date[0]
          dateEnd = this.search.date[1]
        }
        this.reservationsCount = await GetReservationCount({
          shopId: this.shop,
          dateStart,
          dateEnd,
          userName: !this.search.userName ? undefined : this.search.userName.trim(),
          AppointmentUnitId: !this.search.unit ? undefined : this.search.unit.id,
          AppointmentServiceId: !this.search.service ? undefined : this.search.service.id,
          ResourceItemId: (this.search.resource === '') ? undefined : this.search.resource.id,
          code: !this.search.code ? undefined : this.search.code.trim(),
          customFlowNodeId: this.search.customFlowId || undefined,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 刪除(取消)預約
    async cancelReservation () {
      const [, err] = await UpdateReservationOrderStatus({
        shopId: this.shop,
        id: get(this.selectRow, 'AppointmentOrder.id'),
        status: 'cancel',
      })
      if (err) return this.$message.error(err)
      window.$message.success('取消成功')
      this.deleteDialog = false
      // this.showInfo = false
      await this.refresh()
      this.showInfo = false
      // setTimeout(() => {
      //   this.showInfo = true
      // }, 50)
    },

    async updateTableFilter () {
      this.tableRefresh = true
      this.tableFilter = getTableFilterConfig(this.tableName)
      await this.refresh()
      setTimeout(() => {
        this.tableRefresh = false
      }, 0)
    },

    orderTotalPrice (row) {
      const orderPrice = get(row, 'AppointmentOrder.totalPrice')
      if (!orderPrice) return '-'
      return `$ ${orderPrice}`
    },

    depositPrice (row) {
      const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
      if (!depositData) return '-'
      if (depositData.depositPrice === 0) return '-'
      return `$ ${depositData.depositPrice}`
    },

    paymentStatusTagType (row, type) {
      if (type === 'deposit') {
        const depositData = get(row, 'AppointmentOrder.AppointmentOrderDeposit')
        if (!depositData) return ''
        const status = depositData.status
        return get(depositStatusConfig[status], 'tag')
      } else if (type === 'checkout') {
        const checkoutData = get(row, 'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment')
        if (!checkoutData) return ''
        const status = checkoutData.status
        return get(depositStatusConfig[status], 'tag')
      }
    },

    createdAtFormat (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },
  },
}
</script>

<style scoped lang="postcss">
.reservations-list {
  height: 100%;
}
::v-deep .test.el-select,
::v-deep .test .el-input {
  max-width: 196px !important;
}
::v-deep .test2.el-select,
::v-deep .test2.el-input,
::v-deep .test2 .el-input,
::v-deep.test2.el-input__inner {
  width: 100% !important;
  max-width: 400px !important;
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}

.filter-row .label {
  @apply flex-shrink-0;
}
.test2.date-picker-width {
  @apply !max-w-[808px];
}
</style>
